import { PDFViewer, Document } from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import { ExportPage } from "../../components/ExportPage";
import { styles } from "./styles";
import { BudgetService } from "../../services/budget.service";
import { useLocation } from "react-router-dom";
import ProjectService from "../../services/project.service";
import { ExportDescriptive } from "../../components/ExportDescriptive";
import { ExportNegotiation } from "../../components/ExportNegotiation";
import { ExportNegotiationDescriptive } from "../../components/ExportNegotiationDescriptive";

function parseVersions(input: string) {
  const parts = input.split("-");
  const id = parts[0];
  const version = parts.slice(1).map(Number);

  return { id, version };
}

export function Export(props: any) {
  const [clientData, setClientData] = useState<any>({
    eventName: "oi",
    clientName: "oi",
    clientRequester: "oi",
    mmCode: "oi",
    initialDate: "oi",
    finalDate: "oi",
    attendance: "oi",
    coordinator: "oi",
    product: "oi",
    pfz: "oi",
    mapp: "oi",
  });

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projectId = searchParams.get("projectId") || "";
  const budgetId = searchParams.get("budgetId") || "";
  const exportType = searchParams.get("type") || "mm";
  const isNegotiate = searchParams.get("status") === "NEGOTIATE";
  const isClosing = searchParams.get("status") === "APROVED";
  const budgets = searchParams.get("budgets") || "";

  const [data, setData] = useState<any>(null);
  const [versions, setVersions] = useState<any[]>([]);

  const getExportData = async () => {
    if (!data) {
      const budgetData = await BudgetService.getBudgetList(projectId);

      const projectData = await ProjectService.get(projectId);

      const formattedProjectData = {
        name: projectData?.client?.name,
        nickname: projectData?.client?.nickname,
        event: {
          name: projectData?.event?.name,
          startDate: projectData?.event?.startDate,
          endDate: projectData?.event?.endDate,
          pfzCode: projectData?.event?.pfzCode,
          mappCode: projectData?.event?.mappCode,
          local: projectData?.event?.local,
          zip_code: projectData?.event?.zip_code,
          number: projectData?.event?.number,
          country: projectData?.event?.country,
          state: projectData?.event?.state,
          city: projectData?.event?.city,
          complement: projectData?.event?.complement,
          neighborhood: projectData?.event?.neighborhood,
        },
        requester: projectData?.requester?.name,
        firstAttendance: projectData?.firstAttendance?.name,
        commercial: projectData?.commercial?.name,
        yearCode: projectData?.yearCode,
        mmCode: projectData?.mmCode,
      };

      if (isNegotiate) {
        let versionsArr: any[] = [];
        if (budgets) {
          const budgetIds = budgets?.split(",");
          budgetIds.forEach((i) => {
            versionsArr.push(parseVersions(i));
          });

          const filteredBudget = budgetData.filter((item: any) =>
            versionsArr.some((i) => i?.id === item?._id)
          );

          setVersions(versionsArr);
          setData(filteredBudget);
        } else {
          setData(budgetData);
        }
      } else if (isClosing) {
        let filteredBudgetData = budgetData.filter(
          (b: any) => b.status === "APROVED"
        );
        if (budgets) {
          const budgetIds = budgets?.split(",");
          const filteredBudget = filteredBudgetData.filter((item: any) =>
            budgetIds.includes(item?._id)
          );
          setData(filteredBudget);
        } else {
          setData(filteredBudgetData);
        }
      } else {
        if (budgets) {
          const budgetIds = budgets?.split(",");
          const filteredBudget = budgetData.filter((item: any) =>
            budgetIds.includes(item?._id)
          );
          setData(filteredBudget);
        } else {
          const filteredBudget = budgetData.filter(
            (item: any) =>
              item?.status !== "DELETED" && item?.status !== "DENIED"
          );
          setData(filteredBudget);
        }
      }

      setClientData(formattedProjectData);
    }
  };

  useEffect(() => {
    getExportData();
  }, []);

  return (
    <PDFViewer style={styles.viewer}>
      <Document title="Comparação de ssto">
        {!isNegotiate && exportType === "mm" && data && (
          <ExportPage
            key={Math.random()}
            clientData={clientData}
            projectData={{}}
            budgetData={data}
            showHeader
            isNegotiate={isNegotiate}
            versions={versions}
          />
        )}
        {!isNegotiate && exportType === "client" && data && (
          <ExportDescriptive
            key={Math.random()}
            clientData={clientData}
            projectData={{}}
            budgetData={data}
            showHeader
            versions={versions}
            showClientHeader
          />
        )}
        {isNegotiate && exportType === "mm" && data && (
          <ExportNegotiation
            key={Math.random()}
            clientData={clientData}
            projectData={{}}
            budgetData={data}
            showHeader
            isNegotiate={isNegotiate}
            versions={versions}
          />
        )}
        {isNegotiate && exportType === "client" && data && (
          <ExportNegotiationDescriptive
            key={Math.random()}
            clientData={clientData}
            projectData={{}}
            budgetData={data}
            showHeader
            versions={versions}
            showClientHeader
          />
        )}
        {!isNegotiate && exportType === "full" && data && (
          <>
            <ExportPage
              key={Math.random()}
              clientData={clientData}
              projectData={{}}
              budgetData={data}
              showHeader
              isNegotiate={isNegotiate}
              versions={versions}
            />
            <ExportDescriptive
              key={Math.random()}
              clientData={clientData}
              projectData={{}}
              budgetData={data}
              showHeader
              versions={versions}
            />
          </>
        )}
        {isNegotiate && exportType === "full" && data && (
          <>
            <ExportNegotiation
              key={Math.random()}
              clientData={clientData}
              projectData={{}}
              budgetData={data}
              showHeader
              isNegotiate={isNegotiate}
              versions={versions}
            />
            <ExportNegotiationDescriptive
              key={Math.random()}
              clientData={clientData}
              projectData={{}}
              budgetData={data}
              showHeader
              versions={versions}
            />
          </>
        )}
      </Document>
    </PDFViewer>
  );
}
