import {
  Box,
  Button,
  FormHelperText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import MonetaryData from "../MonetaryData";
import {
  Descriptions,
  initialServiceData,
  ServiceData,
  ServiceValues,
} from "../../../../types/budget/service-item";
import { LabeledInput } from "../../../UI/LabeledInput";
import {
  EVENT_LOCATIONS,
  ServiceOptions,
} from "../../../../utils/serviceTypesFromBudget";
import { useBudget } from "../../../../contexts/BudgetContext/projectbudgets.context";
import { addHours, differenceInDays, format, isBefore } from "date-fns";
import { useProject } from "../../../../contexts/project.context";
import ServiceTotals from "../ServiceTotals";
import { toast } from "react-toastify";
import { isDateValid } from "../../../../utils/utils";
import Exchange from "../Exchange";
import Composition from "../Composition";
import { ExchangeData } from "../../../../types/budget/budget";
import Swal from "sweetalert2";
type Props = {
  title: string;
  serviceData: ServiceData;
  type: string;
  key: any;
  index: number;
};

const BudgetServiceItem: React.FC<Props> = ({
  serviceData,
  type,
  index,
}: Props) => {
  const { negotiationVersion, activeBudgetDispatch } = useBudget();
  const { tabActive } = useProject();

  const [serviceValues, setServiceValues] = useState<ServiceValues>(
    initialServiceData.budgetValues
  );
  const [compareServiceValues, setCompareServiceValues] = useState(
    {} as ServiceValues
  );
  const [serviceValuesKey, setServiceValuesKey] =
    useState<keyof typeof serviceData>("budgetValues");
  function handleStateChange(
    value: any,
    field: keyof typeof serviceData.budgetValues
  ) {
    if (field === "quantity" && value <= 0) {
      return toast.warn("Quantidade dever ser igual ou maior que 1");
    }

    const newState = { ...serviceValues, [field]: value };
    // calculo de diárias
    if (field.includes("Date")) {
      // validação de datas retroativas
      if (isBefore(new Date(newState.outDate), new Date(newState.inputDate))) {
        return toast.warn(
          "Data de término deve ser posterior à data de início"
        );
      }
      const dailyCalc =
        differenceInDays(
          new Date(newState.outDate),
          new Date(newState.inputDate)
        ) + 1 || 1;
      return setServiceValues({ ...newState, daily: dailyCalc });
    }
    setServiceValues(newState);
  }
  function handleDailyOrQtdBlur(
    value: any,
    field: keyof typeof serviceData.budgetValues
  ) {
    if (field === "quantity" && value <= 0) {
      return toast.warn("Quantidade dever ser igual ou maior que 1");
    }

    const newState = { ...serviceValues, [field]: value };
    // calculo de diárias
    if (field.includes("Date")) {
      // validação de datas retroativas
      if (isBefore(new Date(newState.outDate), new Date(newState.inputDate))) {
        return toast.warn(
          "Data de término deve ser posterior à data de início"
        );
      }
      const dailyCalc =
        differenceInDays(
          new Date(newState.outDate),
          new Date(newState.inputDate)
        ) + 1 || 1;
      setServiceValues({ ...newState, daily: dailyCalc });
    }
    setServiceValues(newState);

    const updatedServiceData = { ...serviceData };
    if (serviceValuesKey === "negotiationValue") {
      updatedServiceData[serviceValuesKey][negotiationVersion - 1] = serviceValues;
    } else if (
      serviceValuesKey === "budgetValues" ||
      "closingValues" === serviceValuesKey
    ) {
      updatedServiceData[serviceValuesKey] = { ...serviceValues };
    }
    activeBudgetDispatch({
      type: "UPDATE_CALC_VALUES",
      payload: updatedServiceData,
    });
  }
  function handleUpdateMonetary(
    value: any,
    field: keyof typeof serviceData.budgetValues
  ) {
    const updatedServiceData = { ...serviceData };
    let newServiceValues = serviceValues;
    if (["fixValue", "hasFixValue"].includes(field)) {
      newServiceValues = { ...newServiceValues, [field]: value };
    } else {
      newServiceValues.taxData = value;
    }
    if (serviceValuesKey === "negotiationValue") {
      updatedServiceData[serviceValuesKey][negotiationVersion - 1] =
        newServiceValues;
    } else if (
      serviceValuesKey === "budgetValues" ||
      "closingValues" === serviceValuesKey
    ) {
      updatedServiceData[serviceValuesKey] = newServiceValues;
    }
    activeBudgetDispatch({
      type: "UPDATE_CALC_VALUES",
      payload: updatedServiceData,
    });
  }

  function handleDeleteService(): void {
    Swal.fire({
      title: "Tem certeza?",
      text: "Você está prestes a apagar um serviço",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Não, cancelar",
      confirmButtonText: "Sim, prossiga",
    }).then((result) => {
      if (result.isConfirmed) {
        activeBudgetDispatch({
          type: "DELETE_SERVICE",
          payload: serviceData._id,
        });
        Swal.fire({
          title: "Apagado",
          text: "O serviço foi apagado",
          icon: "success",
        });
      }
    });
  }
  useMemo(() => {
    let stepServiceValues = {} as ServiceValues;
    let compareServiceValues = {} as ServiceValues;
    let key: keyof typeof serviceData = "budgetValues";
    switch (tabActive) {
      case 9:
        stepServiceValues =
          serviceData.negotiationValue[negotiationVersion - 1];
        compareServiceValues =
          negotiationVersion === 1
            ? serviceData.budgetValues
            : serviceData.negotiationValue[negotiationVersion - 2];
        key = "negotiationValue";
        break;
      case 10:
        stepServiceValues = serviceData.closingValues;
        compareServiceValues =
          serviceData.negotiationValue[negotiationVersion - 1];
        key = "closingValues";
        break;
      case 8:
      default:
        stepServiceValues = serviceData.budgetValues;
        key = "budgetValues";

        break;
    }
    setServiceValues(stepServiceValues);
    setCompareServiceValues(compareServiceValues);
    setServiceValuesKey(key);
  }, [serviceData, tabActive]);

  function submitForm() {
    const updatedServiceData = { ...serviceData };
    if (serviceValuesKey === "negotiationValue") {
      updatedServiceData[serviceValuesKey][negotiationVersion - 1] = serviceValues;
    } else if (
      serviceValuesKey === "budgetValues" ||
      "closingValues" === serviceValuesKey
    ) {
      updatedServiceData[serviceValuesKey] = { ...serviceValues };
    }
    activeBudgetDispatch({
      type: "UPDATE_SERVICE",
      payload: updatedServiceData,
    });
  }

  function handleExchangeUpdate(newExchangeData: ExchangeData) {
    const newServiceValues = {
      ...serviceValues,
      exchangeData: newExchangeData,
    };
    setServiceValues(newServiceValues);
    const updatedServiceData = { ...serviceData };
    if (serviceValuesKey === "negotiationValue") {
      updatedServiceData[serviceValuesKey][negotiationVersion - 1] =
        newServiceValues;
    } else if (
      serviceValuesKey === "budgetValues" ||
      "closingValues" === serviceValuesKey
    ) {
      updatedServiceData[serviceValuesKey] = { ...newServiceValues };
    }
    activeBudgetDispatch({
      type: "UPDATE_CALC_VALUES",
      payload: updatedServiceData,
    });
  }
  function handleCompositionUpdate(newComposition: Descriptions) {
    setServiceValues({
      ...serviceValues,
      descriptions: newComposition,
    });
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "16px",
        border: "1px solid #eee",
        borderRadius: "4px",
      }}
    >
      <span style={{ fontWeight: "bold" }}>Serviço {index + 1}</span>
      <Box display={"flex"} gap={3} alignItems={"center"} mt={1}>
        <Box width={"100%"}>
          <Box>
            <Typography variant="caption" style={{ marginBottom: "3px" }}>
              Tipo de serviço
            </Typography>
          </Box>
          <Select
            size="small"
            onChange={(e) => handleStateChange(e.target.value, "serviceType")}
            value={serviceValues?.serviceType || "none"}
            fullWidth
            defaultValue="none"
          >
            <MenuItem value="none" disabled>
              Clique para selecionar
            </MenuItem>
            {ServiceOptions[type].map((el: any) => {
              return (
                <MenuItem value={el.value} key={el.value}>
                  {el.text}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>
            {
              ServiceOptions[type].find(
                (el: any) => el.value === compareServiceValues?.serviceType
              )?.text
            }
          </FormHelperText>
        </Box>
        <Box width={"100%"}>
          <Box>
            <Typography variant="caption" style={{ marginBottom: "3px" }}>
              Tipo de local/Aplicação
            </Typography>
          </Box>
          <Select
            size="small"
            fullWidth
            onChange={(e) =>
              handleStateChange(e.target.value, "serviceApplication")
            }
            value={serviceValues?.serviceApplication || "none"}
            defaultValue="none"
          >
            <MenuItem value="none" disabled>
              Clique para selecionar
            </MenuItem>
            {EVENT_LOCATIONS.map((el: any) => {
              return (
                <MenuItem value={el.value} key={el.value}>
                  {el.text}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>
            {
              EVENT_LOCATIONS.find(
                (el) => el.value === compareServiceValues?.serviceApplication
              )?.text
            }
          </FormHelperText>
        </Box>
      </Box>
      <LabeledInput
        label="Descrição do serviço"
        placeholder="Especifique"
        value={serviceValues?.observation}
        helperText={compareServiceValues?.observation}
        onChange={(e) => handleStateChange(e.target.value, "observation")}
      />
      <Box display={"grid"} gridTemplateColumns={"1fr 1fr .4fr .4fr"} gap={3}>
        <LabeledInput
          value={serviceValues?.inputDate}
          helperText={
            isDateValid(compareServiceValues?.inputDate)
              ? format(
                  addHours(new Date(compareServiceValues?.inputDate), 5),
                  "dd/MM/yyyy"
                )
              : undefined
          }
          onChange={(e) => handleStateChange(e.target.value, "inputDate")}
          onBlur={(e) => handleDailyOrQtdBlur(e.target.value, "inputDate")}
          label={`Data de início`}
          type="date"
        />
        <LabeledInput
          value={serviceValues?.outDate}
          helperText={
            isDateValid(compareServiceValues?.inputDate)
              ? format(
                  addHours(new Date(compareServiceValues?.outDate), 5),
                  "dd/MM/yyyy"
                )
              : undefined
          }
          onChange={(e) => handleStateChange(e.target.value, "outDate")}
          onBlur={(e) => handleDailyOrQtdBlur(e.target.value, "outDate")}
          label={`Data de término`}
          type="date"
        />
        <LabeledInput
          disabled
          value={serviceValues?.daily} // fix later
          helperText={compareServiceValues?.daily}
          label={`Dias totais`}
        />
        <LabeledInput
          value={serviceValues?.quantity}
          helperText={compareServiceValues?.quantity}
          onChange={(e) => {
            handleStateChange(Number(e.target.value) || 1, "quantity");
          }}
          onBlur={(e) =>
            handleDailyOrQtdBlur(Number(e.target.value) || 1, "quantity")
          }
          label={`Quantidade`}
        />
      </Box>
      <Exchange
        savedExchangeData={
          serviceValues?.exchangeData || { currency: "BRL", value: 1, date: "" }
        }
        compareExchangeData={
          compareServiceValues?.exchangeData || {
            currency: "BRL",
            value: 1,
            date: "",
          }
        }
        onSubmitExchange={handleExchangeUpdate}
      />

      <Box border={"1px solid #eee"} borderRadius={"4px"}>
        <MonetaryData
          taxData={serviceValues?.taxData}
          compareTaxData={compareServiceValues?.taxData}
          exchange={
            serviceValues?.exchangeData || {
              currency: "BRL",
              date: "",
              value: 1,
            }
          }
          onChange={handleUpdateMonetary}
          itemType={type}
        />
      </Box>
      <Composition
        savedComposition={
          serviceValues?.descriptions || {
            cancelPolicy: "",
            composition: "",
            paymentMethod: "",
          }
        }
        compareComposition={compareServiceValues?.descriptions}
        onChangeComposition={handleCompositionUpdate}
      />
      <ServiceTotals
        fullCoinService={serviceValues?.totals.totalInBRL || 0}
        totalServiceConverted={serviceValues?.totals.totalInCurrency || 0}
        exchangeCode={serviceValues?.exchangeData?.currency || "BRL"}
      />

      <Box gap={1} display={"flex"} ml={"auto"} mt={1}>
        <Button
          sx={{
            border: "1px solid #FF6262",
            "&:hover": {
              border: "1px solid #FF6262",
            },
          }}
          variant="outlined"
          size="small"
          onClick={() => handleDeleteService()}
        >
          <span style={{ color: "#FF6262", cursor: "pointer" }}>
            Remover serviço
          </span>
        </Button>
        <Button
          sx={{ height: "80%" }}
          variant="outlined"
          size="small"
          onClick={submitForm}
        >
          <span
            style={{
              color: "#1361A4",
              cursor: "pointer",
            }}
          >
            Salvar
          </span>
        </Button>
      </Box>
    </Box>
  );
};

export default BudgetServiceItem;
