import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import TaxWithConvertedValue from "../TaxWithConvertedValue";
import { createOptionsFromEnum } from "../../../../utils/formatters";
import {
  typeValueOptions,
  cortesyOptions,
  NFMMOptions,
  typeTax,
  DefaultTax,
  TaxData,
  ServiceValues,
  TaxKey,
  mapTypeTaxToTaxKey,
} from "../../../../types/budget/service-item";
import NumberFormat from "react-number-format";
import { BiChevronDown } from "react-icons/bi";
import { ExchangeData } from "../../../../types/budget/budget";

const valueTypeOptionsDefault = createOptionsFromEnum(typeValueOptions);
const valueCortesyOptions = createOptionsFromEnum(cortesyOptions);
const typeTaxOptions = createOptionsFromEnum(typeTax);
const nfMMOptions = createOptionsFromEnum(NFMMOptions);

type Props = {
  taxData: TaxData;
  compareTaxData?: TaxData;
  exchange: ExchangeData;
  itemType: string;
  onChange: (value: any, field: keyof ServiceValues) => void;
};

const MonetaryData: React.FC<Props> = ({
  taxData,
  compareTaxData,
  exchange,
  itemType,
  onChange,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const handleCalcValues = (
    type: string,
    taxParameters: { currencyValue: number; typeTax: string }
  ) => {
    const currState = JSON.parse(JSON.stringify(taxData));
    switch (type) {
      case "SERVICE":
        currState.serviceTax.value = taxParameters?.currencyValue || 0;
        currState.serviceTax.typeTax = taxParameters?.typeTax || "";
        break;
      case "ISS":
        currState.iss.value = taxParameters?.currencyValue || 0;
        currState.iss.typeTax = taxParameters?.typeTax || "";
        break;
      case "TARIFF":
        currState.tariff.value = taxParameters?.currencyValue || 0;
        currState.tariff.typeTax = taxParameters?.typeTax || "";
        break;
      case "LOCAL_TAX":
        currState.localTax.value = taxParameters?.currencyValue || 0;
        currState.localTax.typeTax = taxParameters?.typeTax || "";
        break;
      case "TOURISM_TAX":
        currState.tourismTax.value = taxParameters?.currencyValue || 0;
        currState.tourismTax.typeTax = taxParameters?.typeTax || "";
        break;
      case "FEE":
        currState.fee.value = taxParameters?.currencyValue || 0;
        currState.fee.typeTax = taxParameters?.typeTax || "";
        break;
      // case "NFMM":
      //   currState.typeNfMm.typeCalculate = taxParameters?.typeTax || "";
      //   break;
      case "IOF":
        currState.iof.value = taxParameters?.currencyValue || 0;
        currState.iof.typeTax = taxParameters?.typeTax || "";
        break;
      case "MARKUP":
        currState.markup.value = taxParameters?.currencyValue || 0;
        currState.markup.typeTax = taxParameters?.typeTax || "";
        break;
      case "OVER":
        currState.overhead.value = taxParameters?.currencyValue || 0;
        currState.overhead.typeTax = taxParameters?.typeTax || "";
        break;
      case "COMMISSION":
        currState.commission.value = taxParameters?.currencyValue || 0;
        currState.commission.typeTax = taxParameters?.typeTax || "";
        break;
      case "INCENTIVE":
        currState.incentive.value = taxParameters?.currencyValue || 0;
        currState.incentive.typeTax = taxParameters?.typeTax || "";
        break;
      default:
        break;
    }
    onChange(currState, "taxData");
  };
  function definePreviousValue(
    type: keyof typeof typeTax,
    referenceData: TaxData | undefined
  ) {
    switch (type) {
      case "SERVICE":
        return referenceData?.serviceTax;
      case "ISS":
        return referenceData?.iss;
      case "TARIFF":
        return referenceData?.tariff;
      case "LOCAL_TAX":
        return referenceData?.localTax;
      case "TOURISM_TAX":
        return referenceData?.tourismTax;
      case "FEE":
        return referenceData?.fee;
      case "IOF":
        return referenceData?.iof;
      case "MARKUP":
        return referenceData?.markup;
      case "OVER":
        return referenceData?.overhead;

      case "COMMISSION":
        return referenceData?.commission;
      case "INCENTIVE":
        return referenceData?.incentive;
        break;
      default:
        return {
          typeTax: "",
          value: 0,
          currencyValue: 0,
          valueConverted: 0,
        } as DefaultTax;
    }
  }

  return (
    <Accordion
      expanded={isExpanded}
      onChange={(e) => setIsExpanded(!isExpanded)}
    >
      <AccordionSummary expandIcon={<BiChevronDown />}>
        <span style={{ fontWeight: "bold" }}>Valores</span>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          {typeTaxOptions.map(
            (el: any, index: React.Key | null | undefined) => {
              const key: TaxKey =
                mapTypeTaxToTaxKey(typeTax[el.value as keyof typeof typeTax]) ||
                "localTax";
              if (itemType !== "ACCOMMODATIONS" && el.value === "TOURISM_TAX")
                return <></>;
              if (
                (["BRL", ""].includes(exchange?.currency) &&
                  el.value === "IOF") ||
                (!["BRL"].includes(exchange?.currency) && el.value === "ISS")
              )
                return <></>;

              const calcOption =
                el.value === "TARIFF"
                  ? valueCortesyOptions
                  : el.value === "NFMM"
                  ? nfMMOptions
                  : valueTypeOptionsDefault;

              return (
                <TaxWithConvertedValue
                  key={index}
                  type={el.value}
                  title={el.label}
                  previousState={definePreviousValue(el.value, taxData)}
                  calculusOptions={calcOption}
                  triggerCalculus={handleCalcValues}
                  disableChanges={false}
                  selectedCurrencyCode={exchange.currency}
                  comparisonState={definePreviousValue(
                    el.value,
                    compareTaxData
                  )}
                />
              );
            }
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default MonetaryData;
