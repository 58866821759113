import { FormControlLabel, Radio, FormControlLabelProps, RadioProps } from "@mui/material";
import React from "react";

interface Props extends RadioProps {
  value: string;
  label: React.ReactNode;
  onClick?: () => void
  disabled?: boolean;
}

const RadioGroupItem = ({ value, label, disabled = false, onClick, ...props }: Props) => {
  return (
    <FormControlLabel
      disabled={disabled}
      value={value}
      label={label}
      onClick={onClick}
      name={props.name}
      control={<Radio />}
      sx={{
        "& span.MuiTypography-root": {
          fontSize: 14,
        },
      }}
    />
  );
};

export default RadioGroupItem;
