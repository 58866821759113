import {
  createContext,
  Dispatch,
  useContext,
  useMemo,
  useReducer,
  useState,
} from "react";

import { BudgetService } from "../../services/budget.service";
import {
  AllTotals,
  Budget,
  BudgetItemTypeEnum,
  BudgetStatus,
  ByItemType,
  HeaderOptions,
} from "../../types/budget/budget";
import { ServiceData } from "../../types/budget/service-item";
import { ActiveBudgetAction, activeBudgetReducer } from "./reducer-funcion";
import { toast } from "react-toastify";
import { initialItemTypeList } from "./consts";
import { useProject } from "../project.context";

export type BudgetContextProps = {
  notification?: any;
  loadingBudget?: boolean;
  isLoadingCalcs: boolean;
  projectId?: string;
  budgetList?: Budget[];
  negotiations: Budget[];
  approveds: Budget[];
  activeBudget: Budget;
  activeBudgetIndex: number;
  activeBudgetServices?: ServiceData[];
  activeBudgetServicesPerSupplier: StringMap;
  activeBudgetTotals: any;
  negotiationVersion: number;
  activeCalcOption: HeaderOptions;
  hasNegotiations: boolean;
  hasClosings: boolean;
  createBudget: (budgetData: Budget) => void;
  updateBudget: (budgetId: string, budgetData: Budget) => void;
  loadBudgetServices: (budgetId: string) => void;
  loadBudgetList: (projectId: string) => void;
  closeNotification: () => void;
  showNotification: (message: string, severity: string) => void;
  clearBudget: () => void;
  createEmptyBudget: () => void;
  setIsLoadingCalcs: (value: boolean) => void;
  calculateBudgetTotals: (payload: any) => void;
  activeBudgetDispatch: Dispatch<ActiveBudgetAction>;
  setNegotiationVersion: (version: number) => void;
  setActiveCalcOption: (option: HeaderOptions) => void;
};
type StringMap = {
  [key in keyof typeof BudgetItemTypeEnum | string]: any;
};
const BudgetContext = createContext<BudgetContextProps>(
  {} as BudgetContextProps
);

function BudgetProvider({ children }: any) {
  const { setTabActive } = useProject();
  const [activeBudget, activeBudgetDispatch] = useReducer(
    activeBudgetReducer,
    {} as Budget
  );
  const [negotiationVersion, setNegotiationVersion] = useState(-1);
  const [hasNegotiations, setHasNegotiations] = useState(false);
  const [hasClosings, setHasClosings] = useState(false);
  const [activeCalcOption, setActiveCalcOption] = useState<HeaderOptions>("A");
  const [loadingBudget, setLoadingBudget] = useState(false);
  const [isLoadingCalcs, setIsLoadingCalcs] = useState(false);
  const [activeBudgetTotals, setActiveBudgetTotals] = useState<any>();
  const [activeBudgetServices, setActiveBudgetServices] = useState<
    ServiceData[]
  >([] as ServiceData[]);
  const [activeBudgetServicesPerSupplier, setActiveBudgetServicesPerSupplier] =
    useState<StringMap>({} as StringMap);
  const [budgetList, setBudgetList] = useState<Budget[]>([] as Budget[]);
  const [activeBudgetIndex, setActiveBudgetIndex] = useState<number>(0);
  const [projectId, setProjectId] = useState<string>();
  const [notification, setNotification] = useState<any>({
    open: false,
    message: "",
    severity: "success",
  });

  useMemo(() => {
    async function updateBudget() {
      setIsLoadingCalcs(true);
      try {
        if (activeBudget._id) {
          const updatedFromApi = await BudgetService.update(
            activeBudget._id,
            activeBudget
          );
          activeBudgetDispatch({
            type: "UPDATE_FROM_API",
            payload: { ...updatedFromApi, isApiUpdate: true },
          });
        }
      } catch (error) {
        toast.warn("Houve um problema ao salvar as alterações");
      }
      setIsLoadingCalcs(false);
    }
    if (activeBudget?._id && !activeBudget?.isApiUpdate) {
      updateBudget();
      const newBudgetList = [...budgetList];
      const newActiveBudgetIndex = budgetList.findIndex(
        (el) => el._id === activeBudget._id
      );
      newBudgetList[newActiveBudgetIndex] = activeBudget;
      if (!!newBudgetList.find(el => el.status === "NEGOTIATE")) setHasNegotiations(true)
      if (!!newBudgetList.find(el => el.status === "APROVED")) setHasClosings(true)
      setBudgetList(newBudgetList);
    }
  }, [activeBudget]);

  async function createEmptyBudget() {
    if (!!projectId)
      await createBudget({
        project: projectId,
        status: "OPEN",
        budgetServices: [],
        paymentSolicitation: [],
        itemTypeList: JSON.parse(JSON.stringify(initialItemTypeList)),
        supplierList: [],
        addedItemTypeList: [],
        budgetValuesTotal: {
          byCalculationType: {} as AllTotals,
          byItemType: {} as ByItemType,
        },
        negociationValuesTotal: [] as AllTotals[],
        closingValuesTotal: {} as AllTotals,
        negotiationVersion: 0,
        files: [],
        versionHistory: [{ status: 'OPEN', version: 0 }],
        budgetIndex: 0
      });
  }

  const negotiations =
    budgetList?.filter((budget) => budget.status === "NEGOTIATE") ?? [];

  const approveds =
    budgetList?.filter(
      (budget) =>
        budget.status === "APROVED" || budget.status === "APPROVED_NEGOTIATION"
    ) ?? [];

  async function updateBudget(budgetId: string, budgetData: Budget) {
    try {
      if (budgetId) {
        await BudgetService.update(budgetId, budgetData);
        activeBudgetDispatch({
          type: "NEW_ACTIVE_BUDGET",
          payload: budgetData,
        });
        if (budgetData.status === "APROVED") setTabActive(10);
        if (budgetData.status === "NEGOTIATE") {
          setTabActive(9);
          setNegotiationVersion(budgetData.negotiationVersion)
        }
        showNotification(`Salvo com sucesso!`);
      }
    } catch (error) {
      showNotification(`Não foi possível atualizar o orçamento`, "warning");
    }
  }

  // vai ser cortado  pois vai
  async function loadBudgetServices(budgetId: string) {
    setLoadingBudget(true);

    if (budgetId.trim() === "") return;
    try {
      const newActiveBudget = budgetList.find((el) => el._id === budgetId);
      const newActiveBudgetIndex = budgetList.findIndex(
        (el) => el._id === budgetId
      );
      if (newActiveBudget) {
        setActiveBudgetIndex(newActiveBudgetIndex);
        activeBudgetDispatch({
          type: "NEW_ACTIVE_BUDGET",
          payload: { ...newActiveBudget },
        });
      }
    } catch (error) {
      showNotification(
        "Não foi possível carregar os serviços salvos para o orçamento",
        "warning"
      );
    }
    setLoadingBudget(false);
  }
  interface StringMap {
    [key: string]: any;
  }
  function filterServicesPerSupplier(budgetServices: ServiceData[]) {
    const servicesPerSupplier: StringMap = {};
    budgetServices.map((el) => {
      if (!!servicesPerSupplier[el.supplierId]) {
        servicesPerSupplier[el.supplierId].push(el);
      } else {
        servicesPerSupplier[el.supplierId] = [el];
      }
    });
    return servicesPerSupplier;
  }
  function filterServicesByItemType(budgetServices: ServiceData[]) {
    const filtered: StringMap = {
      ACCOMMODATIONS: undefined,
      ROOMS: undefined,
      AEB: undefined,
      EQUIPMENTS: undefined,
      TRANSLATIONS: undefined,
      SUPPORT: undefined,
      SUBSCRIPTION: undefined,
      RSVP: undefined,
      AIR: undefined,
      TRANSFER: undefined,
      COMMUNICATIONS: undefined,
      SEVERAL: undefined,
      TOTALS: undefined,
    };
    for (const [key, value] of Object.entries(filtered)) {
      const services = budgetServices.filter((service) => service.type === key);
      filtered[key] = services;
    }
    return filtered;
  }
  function showNotification(message: string, severity = "success") {
    setNotification({ open: true, message, severity });
  }

  function closeNotification() {
    setNotification({ ...notification, open: false });
  }

  async function loadBudgetList(projectId: string) {
    setLoadingBudget(true);

    try {
      setProjectId(projectId);
      const budgetList = await BudgetService.getBudgetList(projectId);
      if (budgetList.length > 0) {
        const negotiationBudgets = budgetList.filter(
          (el: Budget) => el.negotiationVersion > 0
        );
        const closingBudget = budgetList.find(
          (el: Budget) => el.status === "APROVED"
        );
        const activeBudgets = budgetList.filter(
          (el: any) => el.status !== "DELETED"
        );
        if (negotiationBudgets.length > 0) setHasNegotiations(true);
        if (!!closingBudget) setHasClosings(true);
        setBudgetList(activeBudgets);
        setActiveBudgetIndex(0);
        activeBudgetDispatch({
          payload: activeBudgets[0],
          type: "NEW_ACTIVE_BUDGET",
        });
      } else {
        setBudgetList([]);
        activeBudgetDispatch({ payload: undefined, type: "NO_ACTIVE_BUDGET" });
      }
    } catch (error) {
      showNotification("Não foi possível carregar", "warning");
    }
    setLoadingBudget(false);
  }

  async function createBudget(data: Budget) {
    try {
      const budgetData = await BudgetService.create(data);
      showNotification("Criado com Sucesso!", "success");
      setBudgetList([...budgetList, budgetData]);
      activeBudgetDispatch({ payload: budgetData, type: "NEW_ACTIVE_BUDGET" });
    } catch (error) {
      showNotification("Não foi possível criar", "warning");
    }
  }
  async function calculateBudgetTotals(payload: any) {
    try {
      const budgetTotals = await BudgetService.calculateBudgetTotals(payload);
      setActiveBudgetTotals(budgetTotals);
    } catch (error) {
      showNotification(
        "Não foi possível calcular os totais do orçamento",
        "warning"
      );
    }
  }

  function clearBudget() {
    setBudgetList([]);
    activeBudgetDispatch({ payload: undefined, type: "NO_ACTIVE_BUDGET" });
    setActiveBudgetServicesPerSupplier({} as StringMap);
    setProjectId(undefined);
  }

  return (
    <BudgetContext.Provider
      value={{
        isLoadingCalcs,
        notification,
        loadingBudget,
        projectId,
        budgetList,
        negotiations,
        approveds,
        activeBudgetServices,
        activeBudgetServicesPerSupplier,
        activeBudget,
        activeBudgetTotals,
        negotiationVersion,
        activeCalcOption,
        activeBudgetIndex,
        hasClosings,
        hasNegotiations,
        setActiveCalcOption,
        setNegotiationVersion,
        createBudget,
        updateBudget,
        loadBudgetServices,
        loadBudgetList,
        closeNotification,
        showNotification,
        clearBudget,
        calculateBudgetTotals,
        createEmptyBudget,
        setIsLoadingCalcs,
        activeBudgetDispatch,
      }}
    >
      {children}
    </BudgetContext.Provider>
  );
}

const useBudget = () => useContext(BudgetContext);
export { BudgetProvider, useBudget };
