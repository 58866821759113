import { useBudget } from "../../../../contexts/BudgetContext/projectbudgets.context";
import Swal from "sweetalert2";
import {
  AddedTypes,
  BudgetItemType,
  BudgetItemTypeEnum,
  initialPaymentSolicitation,
} from "../../../../types/budget/budget";
import { BudgetItem } from "../BudgetItem";
import { Box, Button } from "@mui/material";
import SupplierAutoComplete from "../../SupplierAutoComplete";
import ActionSelect from "../RightSideBar/ActionSelect";
import { toast } from "react-toastify";
import { FaTimesCircle, FaTrashAlt } from "react-icons/fa";
import { useState } from "react";
import { useQuery } from "react-query";
import SupplierService from "../../../../services/supplier.service";

type PropTypes = {
  supplierId: string;
};

export default function BudgetSupplier({ supplierId }: PropTypes) {
  const { activeBudgetDispatch, activeBudget, activeCalcOption, negotiationVersion } = useBudget();
  const [accordionState, setAccordionState] = useState<Map<string, boolean>>(
    new Map()
  );

  const { data: provider } = useQuery({
    queryKey: ["provider", supplierId],
    queryFn: () => SupplierService.getProviders({ id: supplierId }),
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: false, // Disable refetch on window focus
    refetchOnMount: false, // Disable refetch on component mount
    cacheTime: 1000 * 60 * 10, // Data stays in cache for 10 minutes
  });

  // Função para gerar as opções
  function generateFilteredOptions(
    enumObj: typeof BudgetItemTypeEnum,
    itemTypeList: { [key in keyof typeof BudgetItemTypeEnum]?: boolean }
  ) {
    if (!itemTypeList) {
       return []
      //  Object.keys(enumObj) // Pega as chaves do enum
      //   .map((key) => ({
      //     label: enumObj[key as keyof typeof BudgetItemTypeEnum],
      //     value: key,
      //   })); // Cria o array no formato desejado
    }
    return Object.keys(enumObj) // Pega as chaves do enum
      .filter((key) => itemTypeList[key as keyof typeof itemTypeList]) // Filtra apenas as chaves que estão com valor `true` em itemTypeList
      .map((key) => ({
        label: enumObj[key as keyof typeof BudgetItemTypeEnum],
        value: key,
      })); // Cria o array no formato desejado
  }

  function handleStateChange(value: any, _: any) {
    activeBudgetDispatch({
      type: "UPDATE_SUPPLIER_LIST",
      payload: {
        currentId: supplierId,
        newId: value,
        calcOption: activeCalcOption,
      },
    });
  }

  function addOption(optionValue: string) {
    const hasItemAlready = activeBudget?.addedItemTypeList?.findIndex(
      (el) =>
        el.supplierId === supplierId &&
        el.type === optionValue &&
        el.calcOption === activeCalcOption
    );
    if (hasItemAlready > -1)
      return toast.warn("A opção selecionada já existe para esse fornecedor");
    if (!supplierId)
      return toast.warn("Selecione um fornecedor para adicionar serviços.");
    activeBudgetDispatch({
      type: "ADD_BUDGET_ITEM",
      payload: { type: optionValue, supplierId, calcOption: activeCalcOption },
    });
  }
  const isExpanded = (panel: string) => {
    return accordionState.get(panel) || false;
  };
  const handleChange =
    (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
      setAccordionState((prevState) => {
        const newState = new Map(prevState);
        newState.set(panel, isExpanded);
        return newState;
      });
    };
  function renderActiveOptions() {
    return activeBudget?.addedItemTypeList?.map(
      (el: AddedTypes, index: number) => {
       
        const savedPaymentInfo =
          activeBudget?.paymentSolicitation?.find(
            (paymentInfo) =>
              paymentInfo.type === el.type &&
              paymentInfo.supplierId === supplierId &&
              paymentInfo.calcOption === activeCalcOption
          ) || initialPaymentSolicitation;
        const panel = "panel" + index;
        return el.supplierId === supplierId &&
          el.calcOption === activeCalcOption ? (
          <Box width={"100%"}>
            <BudgetItem
              type={el.type}
              key={el.type + Math.random()}
              supplierId={supplierId}
              supplierName={provider?.[0].name || ""}
              paymentInfo={savedPaymentInfo}
              expanded={isExpanded(panel)}
              onChange={handleChange}
              panel={panel}
              onRemoveItem={removeBudgetItem}
            />
          </Box>
        ) : (
          <></>
        );
      }
    );
  }
  function removeBudgetItem(type: BudgetItemType) {
    Swal.fire({
      title: "Tem certeza?",
      text: "Ao apagar este item todos serviços relacionados também serão apagados!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Não, cancelar",
      confirmButtonText: "Sim, prossiga",
    }).then((result) => {
      if (result.isConfirmed) {
        activeBudgetDispatch({
          type: "DELETE_BUDGET_ITEM",
          payload: {
            type,
            supplierId,
            calcOption: activeCalcOption,
          },
        });
        Swal.fire({
          title: "Apagado",
          text: "O item foi apagado",
          icon: "success",
        });
      }
    });
  }
  function removeSupplier() {
    Swal.fire({
      title: "Tem certeza?",
      text: "Ao apagar este fornecedor todos os dados relacionados também serão apagados!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Não, cancelar",
      confirmButtonText: "Sim, prossiga",
    }).then((result) => {
      if (result.isConfirmed) {
        activeBudgetDispatch({
          type: "DELETE_SUPPLIER",
          payload: {
            supplierId,
            calcOption: activeCalcOption,
          },
        });
        Swal.fire({
          title: "Apagado",
          text: "O item foi apagado",
          icon: "success",
        });
      }
    });
  }
  return (
    <Box
      sx={{
        background: "#E9E9EA",
        borderRadius: "8px",
        padding: "15px",
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "100%",
        marginTop: "5px",
        position: "relative",
      }}
    >
      <SupplierAutoComplete
        onSelectSupplier={handleStateChange}
        provider_id={supplierId}
        title={``}
        disabled={
          activeBudget.budgetServices.filter(
            (el) => el.supplierId === supplierId
          ).length > 0
        }
        isBudget={true}

      />

      <Box mt={1} width={"100%"}>
        {renderActiveOptions()}
      </Box>

      <Box display={"flex"} justifyContent={"flex-end"} p={1} gap={1}>
        <Button
          sx={{
            border: "1px solid #FF6262",
            color: "#FF6262",
            cursor: "pointer",
            background: "#FFF",
            "&:hover": {
              border: "1px solid #FF6262",
            },
            p: 0.62,
            height: "100%",
          }}
          variant="outlined"
          onClick={removeSupplier}
        >
          Remover fornecedor
        </Button>
        <ActionSelect
          label="Adicionar tipo de serviço"
          actionOptions={generateFilteredOptions(
            BudgetItemTypeEnum,
            activeBudget?.itemTypeList?.[activeCalcOption]
          )}
          triggerAction={addOption}
        />
      </Box>
    </Box>
  );
}
