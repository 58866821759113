import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@mui/material";
import ActionSelect from "../ActionSelect";
import {
  Budget,
  BudgetItemType,
  BudgetStatusEnum,
  BudgetSummaryActionOptions,
  itemOptions,
  StatusBudget,
} from "../../../../../types/budget/budget";
import { useState } from "react";
import { useBudget } from "../../../../../contexts/BudgetContext/projectbudgets.context";
import { createOptionsFromEnum } from "../../../../../utils/formatters";
import { BiChevronDown } from "react-icons/bi";
import { ItemTotalsSummary } from "../../BudgetItem/ItemTotalsSummary";
import BudgetItem from "../BudgetDetail/BudgetItem";
import { RadioGroup } from "../../../../UI/RadioGroup";
import { useProject } from "../../../../../contexts/project.context";
import Swal from "sweetalert2";

type Props = {
  budget: Budget;
  index: number;
  status?: StatusBudget;
  version?: number;
};
const BudgetSummary: React.FC<Props> = ({
  budget,
  index,
  status,
  version,
}: Props) => {
  const { tabActive } = useProject();
  const title = (
    <span>
      {budget.name || `Orçamento ${index + 1}`} &nbsp;
      {tabActive === 9 ? (
        <span
          style={{
            padding: "5px",
            background: "#1361A4",
            color: "#FFF",
            borderRadius: "4px",
          }}
        >
          Negociação {version}{" "}
        </span>
      ) : (
        <span
          style={{
            padding: "5px",
            background: "#1361A4",
            color: "#FFF",
            borderRadius: "4px",
          }}
        >
          {BudgetStatusEnum[status || "OPEN"]}
        </span>
      )}
    </span>
  );

  const [isExpanded, setIsExpanded] = useState(false);
  const { createBudget, updateBudget } = useBudget();

  const actionOptions = createOptionsFromEnum(BudgetSummaryActionOptions);

  type ActionMethodsMap = {
    [key in keyof typeof BudgetSummaryActionOptions]: any;
  };
  const actionMethods: ActionMethodsMap = {
    APROVE: () => {
      Swal.fire({
        title: "Alterar status do orçamento?",
        text: `Você está alterando o status do ${
          budget.name || `Orçamento ${budget.budgetIndex + 1}`
        } para Aprovado.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sim, alterar",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          if (!!budget._id) {
            updateBudget(budget._id, {
              ...budget,
              status: "APROVED",
              versionHistory: budget.versionHistory.map((el) => {
                if (el.version === version) {
                  el.status = "APROVED";
                }
                return el;
              }),
            });
          }
          Swal.fire({
            title: "Atualizar",
            text: "Status atualizado",
            icon: "success",
          });
        }
      });
    },
    DENY: () => {
      Swal.fire({
        title: "Alterar status do orçamento?",
        text: `Você está alterando o status de ${
          budget.name || `Orçamento ${budget.budgetIndex + 1}`
        } para NEGADO.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sim, alterar",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          if (!!budget._id) {
            updateBudget(budget._id, {
              ...budget,
              versionHistory: budget.versionHistory.map((el) => {
                if (el.version === version) {
                  el.status = "DENIED";
                }
                return el;
              }),
            });
          }
          Swal.fire({
            title: "Atualizar",
            text: "Status atualizado",
            icon: "success",
          });
        }
      });
    },
    NEGOTIATE: () => {
      Swal.fire({
        title: "Alterar status do orçamento?",
        text: `Você está alterando o status de ${
          budget.name || `Orçamento ${budget.budgetIndex + 1}`
        } para Negociação.`,
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sim, alterar",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          if (!!budget._id) {
            updateBudget(budget._id, {
              ...budget,
              status: "NEGOTIATE",
              negotiationVersion: budget.negotiationVersion + 1,
              versionHistory: [
                ...budget.versionHistory,
                { status: "NEGOTIATE", version: budget.versionHistory.length },
              ],
            });
          }
          Swal.fire({
            title: "Atualizar",
            text: "Status atualizado",
            icon: "success",
          });
        }
      });
    },
    DUPLICATE: () => {
      Swal.fire({
        title: "Duplicar orçamento?",
        text: `Você está duplicando ${budget.name || `Orçamento ${budget.budgetIndex + 1}` }.`,
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sim, duplicar",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (!!budget._id) {
            const newBudget = JSON.parse(JSON.stringify(budget));
            delete newBudget._id;
            newBudget.negotiationVersion = 0;
            newBudget.status = "OPEN";
            await createBudget(newBudget);
          }
          Swal.fire({
            title: "Atualizar",
            text: "Orçamento duplicado",
            icon: "success",
          });
        }
      });
    },
    DELETE: () => {
      Swal.fire({
        title: "Tem certeza que deseja excluir este orçamento?",
        text: `Você está excluindo permanentemente ${budget.name || `Orçamento ${budget.budgetIndex + 1}` }. Esta ação não pode ser desfeita`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sim, excluir permanentemente",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          if (!!budget._id) {
            switch (tabActive) {
              case 9:
                return updateBudget(budget._id, {
                  ...budget,
                  negotiationVersion:
                    budget.negotiationVersion > 1
                      ? budget.negotiationVersion - 1
                      : 0,
                  versionHistory: budget.versionHistory.map((el) => {
                    if (el.version === version) {
                      el.status = "DELETED";
                    }
                    return el;
                  }),
                });
              case 10:
                return;
              case 8:
              default:
                if (!!budget._id) {
                  return updateBudget(budget._id, {
                    ...budget,
                    status: "DELETED",
                    versionHistory: budget.versionHistory.map((el) => {
                      if (el.version === 0) {
                        el.status = "DELETED";
                      }
                      return el;
                    }),
                  });
                }
            }
          }
          Swal.fire({
            title: "Atualizar",
            text: "Orçamento apagado",
            icon: "success",
          });
        }
      });
    },
    NEW_VERSION: () => {
      Swal.fire({
        title: "Criar nova versão?",
        text: `Você está criando uma nova versão de negociação para ${budget.name || `Orçamento ${budget.budgetIndex + 1}` }.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sim, criar",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          if (!!budget._id) {
            updateBudget(budget._id, {
              ...budget,
              negotiationVersion: budget.negotiationVersion + 1,
              versionHistory: [
                ...budget.versionHistory,
                { status: "NEGOTIATE", version: budget.versionHistory.length },
              ],
            });
          }
          Swal.fire({
            title: "Atualizar",
            text: "Nova versão criada",
            icon: "success",
          });
        }
      });
    },
    RENAME: async () => {
      await Swal.fire({
        title: "Editar Nome",
        input: "text",
        inputValue: budget.name, // Preenche o input com o nome atual
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sim, alterar",
        reverseButtons: true,
        inputValidator: (value) => {
          if (!value) return "O nome não pode estar vazio!";
        },
      }).then(async (result) => {
        console.log(result);
        if (result.isConfirmed && budget._id) {
          await updateBudget(budget._id, { ...budget, name: result.value });
          Swal.fire({
            title: "Atualizar",
            text: "O status do orçamento foi atualizado",
            icon: "success",
          });
        }
      });
    },
  };
  async function triggerAction(
    action: keyof typeof BudgetSummaryActionOptions
  ) {
    return actionMethods[action]();
  }

  return (
    <Accordion
      onChange={(_, expanded) => {
        setIsExpanded(expanded);
      }}
      sx={{
        border: "1px solid #eee",
        borderRadius: "4px",
        mb: 0.5,
        fontSize: "13px",
      }}
    >
      <AccordionSummary
        expandIcon={<BiChevronDown />}
        sx={{
          minHeight: 60,
          maxHeight: 60,
          "&.Mui-expanded": {
            minHeight: 30,
            maxHeight: 30,
            mt: 1,
          },
        }}
      >
        <Box display={"flex"} flexDirection={"column"} width={"100%"}>
          <RadioGroup.Item
            label={title}
            value={
              version && budget._id
                ? budget?._id + `-version-${version}`
                : budget._id || ""
            }
            name={!!version ? version.toString() : undefined}
          />
          {!isExpanded && (
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              width={"100%"}
            >
              <ItemTotalsSummary
                total={
                  budget?.budgetValuesTotal?.byItemType?.SUM?.totalInBRL || 0
                }
                savings={0}
              />
              {/* <Box>Total: </Box> <Box ml={"auto"}>R$ 12.987,45 </Box> */}
            </Box>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails style={{ padding: 0 }}>
        {itemOptions.map((el: { value: BudgetItemType; label: string }) => {
          const servicesArr = budget.budgetServices.filter(
            (item) => item.type === el.value
          );
          if (servicesArr.length < 1) return <></>;
          return (
            <BudgetItem
              selectable={false}
              type={el.value}
              servicesArr={budget.budgetServices.filter(
                (item) => item.type === el.value
              )}
              total={
                budget.budgetValuesTotal?.byItemType?.[el.value] || {
                  totalInBRL: 0,
                  totalInCurrency: 0,
                }
              }
              taxTotal={
                // budget.budgetValuesTotal?.["budgetTotals"][el.value].taxes ||
                {
                  totalInBRL: 0,
                  totalInCurrency: 0,
                }
              }
              isSelected={false}
              onSelect={function (): void {}}
            />
          );
        })}
        <Box display={"flex"}>
          <Box ml={"auto"} p={1}>
            <ActionSelect
              label="Ações"
              actionOptions={actionOptions}
              triggerAction={triggerAction}
            />
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
export default BudgetSummary;
