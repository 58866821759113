import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
} from "@mui/material";

import BudgetServiceItem from "../ServiceItem";
import {
  BudgetItemTypeEnum,
  IExchangeAndDescriptionByType,
  IPaymentSolicitationByType,
} from "../../../../types/budget/budget";
import { useBudget } from "../../../../contexts/BudgetContext/projectbudgets.context";
import { useLocation } from "react-router-dom";
import { BiChevronDown } from "react-icons/bi";
import Exchange from "../Exchange";
import Composition from "../Composition";
import { useProject } from "../../../../contexts/project.context";
import { ItemTotalsSummary } from "./ItemTotalsSummary";
import { useMemo, useState } from "react";
import { ServiceData } from "../../../../types/budget/service-item";
import { toast } from "react-toastify";
import PaymentMethodInfo from "../PaymentMethodInfo";
import FileUpload from "../FileUpload";
import { formatCurrency } from "../../../../utils/formatters";
type Props = {
  type: keyof typeof BudgetItemTypeEnum;
  supplierId: string;
  supplierName: string;
  paymentInfo: IPaymentSolicitationByType;
  key: string;
  panel: string;
  expanded: boolean;
  onRemoveItem: (type: keyof typeof BudgetItemTypeEnum) => void;
  onChange: (
    panel: string
  ) => (_event: React.SyntheticEvent, isExpanded: boolean) => void;
};

export function BudgetItem({
  type,
  supplierId,
  paymentInfo,
  supplierName,
  expanded,
  onChange,
  onRemoveItem,
  panel,
}: Props) {
  const [servicesList, setServicesList] = useState<ServiceData[]>([]);
  const [totals, setTotals] = useState<{
    totals: number;
    savings: number;
    totalsInCurrency: number;
  }>({
    savings: 0,
    totals: 0,
    totalsInCurrency: 0,
  });
  const {
    activeBudget,
    activeBudgetDispatch,
    negotiationVersion,
    activeCalcOption,
  } = useBudget();
  const { tabActive } = useProject();
  // const itemTotal = activeBudget?.budgetValuesTotal?.[type]?.totalInBRL || 0;
  // uma lista com os serviços que são do tipo e fornecedor recebidos

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const projectId = searchParams.get("projectId");

  function handleAddNewService() {
    activeBudgetDispatch({
      type: "ADD_SERVICE",
      payload: {
        supplierId,
        supplierName,
        type,
        tabActive,
        calcOption: activeCalcOption,
      },
    });
  }
  function defineTotals() {
    let totals = 0;
    let totalsInCurrency = 0;
    let savings = 0;
    if (activeCalcOption !== "budgetTotals") {
      switch (tabActive) {
        case 9:
          totals =
            activeBudget?.negociationValuesTotal[negotiationVersion]?.[
              activeCalcOption
            ][type]?.find(
              (el) => !!el.supplierId && el.supplierId === supplierId
            )?.totals.totalInBRL || 0;

          totalsInCurrency =
            activeBudget?.negociationValuesTotal[negotiationVersion]?.[
              activeCalcOption
            ][type]?.find(
              (el) => !!el.supplierId && el.supplierId === supplierId
            )?.taxes.totalInBRL || 0;

          savings =
            negotiationVersion === 0
              ? totals -
                (activeBudget?.budgetValuesTotal.byCalculationType?.[
                  activeCalcOption
                ][type]?.find(
                  (el) => !!el.supplierId && el.supplierId === supplierId
                )?.totals.totalInBRL || 0)
              : totals -
                (activeBudget?.negociationValuesTotal[negotiationVersion - 1]?.[
                  activeCalcOption
                ][type]?.find(
                  (el) => !!el.supplierId && el.supplierId === supplierId
                )?.totals.totalInBRL || 0);

          return {
            totals,
            savings,
            totalsInCurrency,
          };
        case 10:
          totals =
            activeBudget?.closingValuesTotal?.[activeCalcOption][type]?.find(
              (el) => !!el.supplierId && el.supplierId === supplierId
            )?.totals.totalInBRL || 0;

          totalsInCurrency =
            activeBudget?.closingValuesTotal?.[activeCalcOption][type]?.find(
              (el) => !!el.supplierId && el.supplierId === supplierId
            )?.taxes.totalInCurrency || 0;
          savings =
            totals -
            (activeBudget?.negociationValuesTotal?.[
              activeBudget?.negociationValuesTotal.length - 1
            ]?.[activeCalcOption][type]?.find(
              (el) => !!el.supplierId && el.supplierId === supplierId
            )?.totals.totalInBRL || 0);

          return {
            totals,
            savings,
            totalsInCurrency,
          };
        case 8:
        default:
          return {
            totals:
              activeBudget?.budgetValuesTotal.byCalculationType?.[
                activeCalcOption
              ][type]?.find(
                (el) => !!el.supplierId && el.supplierId === supplierId
              )?.totals.totalInBRL || 0,

            totalsInCurrency:
              activeBudget?.budgetValuesTotal.byCalculationType?.[
                activeCalcOption
              ][type]?.find(
                (el) => !!el.supplierId && el.supplierId === supplierId
              )?.taxes.totalInBRL || 0,
            savings: 0,
          };
      }
    }
  }

  useMemo(() => {
    console.log("memoizing");
    if (activeBudget?.budgetServices) {
      setServicesList(
        activeBudget?.budgetServices?.filter(
          (el) => el.type === type && el.supplierId === supplierId
        ) || []
      );
      const calculatedTotals = defineTotals();
      setTotals(
        calculatedTotals || { savings: 0, totals: 0, totalsInCurrency: 0 }
      );
    }
  }, [activeBudget._id]);
  return (
    <Accordion
      sx={{ my: 1, borderRadius: "4px", border: "none" }}
      expanded={expanded}
      defaultExpanded={true}
      onChange={onChange(panel)}
    >
      <AccordionSummary
        aria-controls="panel1a-content"
        expandIcon={<BiChevronDown />}
        id="panel1a-header"
        style={{
          backgroundColor: "#F8F8F8",
          borderBottom: "1px solid #eee",
          borderRadius: "4px",
          display: "flex",
        }}
      >
        <span style={{ fontWeight: "bold" }}>{BudgetItemTypeEnum[type]}</span>
        <Box ml={"auto"} mr={1}>
          <ItemTotalsSummary total={totals.totals} savings={totals.savings} />
        </Box>
      </AccordionSummary>
      <Box>{/* total summary */}</Box>

      <AccordionDetails>
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          gap={1}
        >
          {activeBudget?.budgetServices?.length > 0 ? (
            (
              activeBudget?.budgetServices?.filter(
                (el) =>
                  el.type === type &&
                  el.supplierId === supplierId &&
                  el.calcOption === activeCalcOption
              ) || []
            ).map((service: any, index: number) => (
              <BudgetServiceItem
                type={type}
                key={index}
                index={index}
                title={BudgetItemTypeEnum[type]}
                serviceData={service}
              />
            ))
          ) : (
            <Box>
              <h4>Nenhum serviço salvo para {BudgetItemTypeEnum[type]} </h4>
            </Box>
          )}

          <Button
            sx={{ ml: "auto", my: 2 }}
            onClick={handleAddNewService}
            variant="outlined"
          >
            Adicionar serviço
          </Button>
        </Box>

        {tabActive === 10 && (
          <>
            <PaymentMethodInfo
              type={type}
              supplierId={supplierId}
              savedPaymentInfo={paymentInfo}
            />
            <FileUpload />
          </>
        )}

        <Box display={"flex"} justifyContent={"flex-end"}>
          <Button
            sx={{
              border: "1px solid #FF6262",
              color: "#FF6262",
              cursor: "pointer",
              background: "#FFF",
              "&:hover": {
                border: "1px solid #FF6262",
              },
              p: 0.62,
              height: "100%",
            }}
            variant="outlined"
            onClick={(e) => {
              onRemoveItem(type);
            }}
          >
            Remover {BudgetItemTypeEnum[type]}
          </Button>
          <Button
            sx={{ ml: "10px" }}
            variant="outlined"
            onClick={() => {
              toast.success("Salvo com sucesso!");
            }}
          >
            Salvar
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
